import React, { useCallback, useEffect, useState } from 'react';
import Verse from '../mile-marker/Verse';
import verseMap from '../date-milemarker-mappings.json';
import { Link } from 'react-router-dom';
import { useAppState } from '../App';
import { MileMarker } from '../types/MileMarker';
import { MileMarkerDateMapping } from '../types/MileMarkerDateMapping';

function HomePage() {
    const [date, setDate] = useState<string>("");
    const [mileMarkers, setMileMarkers] = useState<MileMarker[]>([]);
    const [bookNumber, setBookNumber] = useState<number>(1);
    const [mileMarkerAudioUrl, setMileMarkerAudioUrl] = useState<string | null>(null);
    const [extraMileAudioUrl, setExtraMileAudioUrl] = useState<string | null>(null);
    const { state } = useAppState();
     
    // const fetchAudioUrls = useCallback(async (bookId: number, mileMarkerId: string) => {
    //     try {
    //         const response = await fetch(
    //             `https://api.adventurecrewonline.com/get-mile-marker-audio?bookNumber=7&mileMarkerNumber=2&getMileMarker=true&getExtraMile=true`
    //         );
    //         const data = await response.json();
    //         setMileMarkerAudioUrl(data.mileMarker);
    //         setExtraMileAudioUrl(data.extraMile);
    //     } catch (error) {
    //         console.error('Error fetching audio URLs:', error);
    //     }
    // }, []);

    const updateMileMarker = useCallback(() => {
        const currentMileMarkerDateMapping = getMileMarkers(verseMap);
        setDate(currentMileMarkerDateMapping.date);
        const bookId = currentMileMarkerDateMapping.bookNumber - 1;
        const mileMarkerIds = currentMileMarkerDateMapping.mileMarkerNumbers.map(x => x - 1);
        const newMileMarkers = mileMarkerIds.map(x => state.books[bookId].mileMarkers[x]);

        setMileMarkers(prevMarkers => {
            const updatedMarkers = [...prevMarkers];
            newMileMarkers.forEach(newMarker => {
                if (!updatedMarkers.some(existingMarker => existingMarker.number === newMarker.number)) {
                    updatedMarkers.push(newMarker);
                }
            });
            return updatedMarkers;
        });
        
        setBookNumber(currentMileMarkerDateMapping.bookNumber);
    }, [state]);

    useEffect(() => {
        updateMileMarker();
        const oneMinuteMillis = 60000;
        const intervalID = setInterval(updateMileMarker, oneMinuteMillis);

        return () => {
            clearInterval(intervalID);
        };
    }, [updateMileMarker]);

    // useEffect(() => {
    //     if (mileMarkers.length > 0) {
    //         fetchAudioUrls(bookNumber, mileMarkers[0].number);
    //     }
    // }, [mileMarkers, bookNumber, fetchAudioUrls]);

    if (!Array.isArray(mileMarkers) || mileMarkers.length === 0) {
        return null;
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-1"></div>
                <div className="col-sm-5">
                    <div className="container pt-2 mt-2">
                        {mileMarkers.length > 1 && (
                            <div className="special-header p-2">
                                <h1 className="mb-0">Review for Book #{bookNumber}</h1>
                                <p>Recite on {new Date(date).toDateString()}</p>
                            </div>
                        )}
                        {mileMarkers.map(mileMarker => (
                            <div key={mileMarker.number} className="p-2">
                                <div className="row">
                                    <div className="col-7">
                                        <h2 className="mb-0">Mile Marker #{mileMarker.number}</h2>
                                        {mileMarkers.length === 1 && (
                                            <div className="special-header">
                                                <p>Recite on {new Date(date).toDateString()}</p>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-5 d-flex justify-content-center align-items-center">
                                        {mileMarkers.length === 1 && (
                                            <Link to={`/books/${bookNumber}/milemarkers/${mileMarker.number}`}>
                                                <button className="btn">View in the Handbook</button>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="my-3 border rounded">
                                    <div className="m-3">
                                        <Verse
                                            reference={mileMarker.mileMarkerReference}
                                            subtitle="Mile Marker"
                                            verseText={mileMarker.mileMarkerText}
                                            bookNumber={bookNumber}
                                            mileMarkerNumber={mileMarker.number}
                                            type="mileMarker"
                                        />
                                    </div>
                                </div>
                                <div className="my-3 border rounded">
                                    <div className="m-3">
                                    <Verse
                                        reference={mileMarker.extraMileReference}
                                        subtitle="Extra Mile"
                                        verseText={mileMarker.extraMileText}
                                        bookNumber={bookNumber}
                                        mileMarkerNumber={mileMarker.number}
                                        type="extraMile"
                                    />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-sm-1"></div>
                <div className="col-sm-5">
                    <div className="d-none d-lg-block circle pt-5 mt-5 mx-auto"></div>
                </div>
            </div>
        </div>
    );
}

const getMileMarkers = (configData: MileMarkerDateMapping[]): MileMarkerDateMapping => {
    const currentDateTime = new Date()
    const validItems = configData.filter((item: any) => ((new Date(item.date)).valueOf() >= currentDateTime.valueOf()))

    if (validItems.length === 0) {
        return { date: "", bookNumber: 1, mileMarkerNumbers: [1] }
    }

    validItems.sort((a: any, b: any) => +new Date(a.date) - +new Date(b.date));
    return validItems[0];
}

export default HomePage;